const VRIcon = ({ size, color } = { size: 200, color: '#FFFFFF' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 512 512"
    fill="none"
    stroke="currentColor"
    strokeWidth="0"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-download"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
    fill={color} stroke="none">
    <path d="M1280 3930 c-220 -47 -407 -147 -559 -300 -38 -38 -121 -140 -184
    -227 -64 -88 -131 -171 -149 -185 -132 -100 -236 -258 -285 -429 -16 -57 -18
    -108 -18 -494 0 -419 1 -432 23 -510 94 -324 350 -557 676 -614 89 -16 473
    -14 586 4 329 50 624 217 842 479 27 32 80 104 118 160 87 129 117 150 219
    154 112 5 147 -16 233 -142 182 -268 345 -416 583 -531 227 -110 382 -138 735
    -132 259 4 293 9 422 61 230 93 413 302 489 556 21 71 23 96 27 465 4 424 -1
    484 -47 617 -47 131 -127 244 -237 337 -34 28 -101 108 -172 205 -63 88 -149
    192 -189 232 -133 132 -308 231 -503 285 l-85 24 -1220 2 c-1151 2 -1225 1
    -1305 -17z m2535 -159 c213 -44 412 -167 546 -337 l46 -59 -1847 0 -1847 0 46
    59 c132 168 332 292 541 337 79 16 169 18 1255 18 1103 1 1175 0 1260 -18z
    m649 -583 c156 -63 274 -169 346 -312 67 -134 72 -175 68 -616 l-4 -385 -26
    -80 c-29 -85 -78 -174 -129 -234 -90 -105 -244 -198 -375 -226 -84 -18 -444
    -20 -560 -4 -335 46 -634 238 -833 534 -93 138 -140 188 -216 228 -57 30 -66
    32 -170 32 -98 0 -116 -3 -166 -26 -81 -38 -130 -87 -222 -223 -139 -206 -295
    -343 -499 -441 -189 -90 -324 -115 -626 -115 -242 0 -306 11 -426 69 -171 84
    -292 222 -354 406 l-26 80 -4 385 c-4 441 1 482 68 615 83 166 224 281 405
    330 41 11 360 13 1865 12 l1815 -2 69 -27z"/>
    <path d="M3335 2712 c-11 -2 -27 -11 -37 -19 -39 -35 -34 -55 88 -322 65 -141
    127 -266 138 -278 25 -29 85 -31 109 -5 25 28 247 518 247 546 0 39 -11 57
    -43 71 -22 9 -39 10 -60 2 -25 -8 -39 -29 -91 -141 -34 -72 -70 -150 -81 -173
    -10 -24 -21 -43 -25 -43 -4 0 -43 77 -86 172 -69 149 -84 173 -109 183 -17 6
    -39 10 -50 7z"/>
    <path d="M4064 2714 c-12 -3 -30 -14 -40 -26 -18 -19 -19 -41 -19 -300 l0
    -280 24 -19 c32 -26 82 -25 109 4 17 18 22 37 24 97 3 74 3 75 30 75 23 0 36
    -14 90 -95 60 -90 65 -95 102 -98 29 -3 44 2 62 19 36 34 31 74 -20 149 l-43
    65 32 37 c96 112 63 283 -69 350 -33 17 -63 21 -151 24 -60 1 -120 0 -131 -2z
    m226 -179 c24 -22 26 -62 3 -87 -12 -13 -31 -18 -75 -18 l-58 0 0 58 c0 32 3
    62 8 66 13 14 101 0 122 -19z"/>
    </g>
  </svg>
)
export default VRIcon
